<template>
  <product title="米动手表青春版 彩色腕带"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="49"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://shop18009901.m.youzan.com/wscgoods/detail/2x6n28hlp08o5"
  ></product>
</template>

<script>
import { CDN_URL } from '@/config'
import Product from '../Product'

export default {
  name: 'bip-colorful-wristbands',
  components: {
    Product
  },
  data () {
    return {
      slogan: '活力炫彩 | 个性张扬',
      colors: [
        '图腾黑',
        '图腾橙',
        '图腾蓝',
        '彩虹'
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/bip-colorful-wristbands/swiper/1.png',
          CDN_URL + '/images/product/bip-colorful-wristbands/swiper/2.png',
          CDN_URL + '/images/product/bip-colorful-wristbands/swiper/3.png'
        ],
        [
          CDN_URL + '/images/product/bip-colorful-wristbands/swiper/4.png',
          CDN_URL + '/images/product/bip-colorful-wristbands/swiper/5.png',
          CDN_URL + '/images/product/bip-colorful-wristbands/swiper/6.png'
        ],
        [
          CDN_URL + '/images/product/bip-colorful-wristbands/swiper/7.png',
          CDN_URL + '/images/product/bip-colorful-wristbands/swiper/8.png',
          CDN_URL + '/images/product/bip-colorful-wristbands/swiper/9.png'
        ],
        [
          CDN_URL + '/images/product/bip-colorful-wristbands/swiper/10.png',
          CDN_URL + '/images/product/bip-colorful-wristbands/swiper/11.png',
          CDN_URL + '/images/product/bip-colorful-wristbands/swiper/12.png'
        ]
      ],
      relativedProducts: [
      ],
      detailImages: [
        CDN_URL + '/images/product/bip-colorful-wristbands/detail/01.jpg',
        CDN_URL + '/images/product/bip-colorful-wristbands/detail/02.jpg',
        CDN_URL + '/images/product/bip-colorful-wristbands/detail/03.jpg',
        CDN_URL + '/images/product/bip-colorful-wristbands/detail/04.jpg',
        CDN_URL + '/images/product/bip-colorful-wristbands/detail/05.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/bip-colorful-wristbands/detail/06.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
